import { useEffect, useState } from 'react';

import { silentRequest } from '../Settings';
import { TokenHandler } from '../utilities/TokenHandler';
import { useAuthority } from './useAuthority';
import { QueryStringParam } from '../utilities';

export const useAccessToken = () => {
  const authority = useAuthority();
  const [accessToken, setAccessToken] = useState<string>('');

  useEffect(() => {
    const request = { ...silentRequest, authority };
    /**
     * This function fetches the access token from the authority
     */
    async function fetchData() {
      const response = await TokenHandler.getToken(request);
      setAccessToken(response.accessToken);
    }
    fetchData();
  }, [authority]);

  return accessToken;
};

export const getAccessToken = async () => {
  const request = { ...silentRequest };
  request.authority = sessionStorage.getItem(QueryStringParam.Authority) ?? '';
  const response = await TokenHandler.getToken(request);
  return response.accessToken;
};
