import { useIntl } from 'react-intl';

export const useStepsToSuccessPages = () => {
  const intl = useIntl();

  return [
    {
      title: 'reviewYourCourseDetails',
      stepName: intl.formatMessage({
        defaultMessage: '1. Review your course details'
      }),
      stepPath: '/coursedetails'
    },
    {
      title: 'bePrepared',
      stepName: intl.formatMessage({
        defaultMessage: '2. Be prepared'
      }),
      stepPath: '/beprepared'
    },
    {
      title: 'buildConfidenceInYourSkills',
      stepName: intl.formatMessage({
        defaultMessage: '3. Build confidence in your skills'
      }),
      stepPath: '/buildconfidence'
    },
    {
      title: 'celebrateYourMSSkills',
      stepName: intl.formatMessage({
        defaultMessage: '4. Celebrate your Microsoft skills'
      }),
      stepPath: '/celebrateskills'
    },
    {
      title: 'reviewYourLearningPath',
      stepName: intl.formatMessage({
        defaultMessage: '5. Review your learning path'
      }),
      stepPath: '/reviewpath'
    }
  ];
};
