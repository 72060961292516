import { AnyAction, Reducer } from 'redux';

import { IDeleteDuplicateProfileRequest, IDeleteDuplicateProfileResponse } from '../../../models/deleteDuplicateProfileRequest';
import { IMultipleProfileRequest, IMultipleProfileResponse } from '../../../models/multipleProfileRequest';
import { IUpdateAccountRequest, IUpdateAccountRequestResponse } from '../../../models/UpdateAccountRequest';
import { EndpointStateFactory, EndpointStore, IEndpointState } from '../../endpoint';
import {
  GetUpdateAccountWorkEmailOnlyActions,
  GetUpdateAccountActions,
  GetUpdateAccountOidEmailAddressOnlyActions,
  GetMultipleProfiles,
  DeleteDuplicateProfile,
  OfferTransHistoryActions
} from './actions';
import { sagas } from './sagas';
export * from './actions';

const storeName = 'UpdateAccountStore';

export interface IUpdateAccountState extends IEndpointState {
  /** The account update request here */
  readonly updateAccountRequest: IUpdateAccountRequest[];
  readonly updateAccountResponse: IUpdateAccountRequestResponse[];
  readonly multipleProfileRequest: IMultipleProfileRequest[];
  readonly multipleProfileResponse: IMultipleProfileResponse[];
  readonly deleteDuplicateProfileRequest: IDeleteDuplicateProfileRequest[];
  readonly deleteDuplicateProfileResponse: IDeleteDuplicateProfileResponse[];
  readonly offerTransactionResponse: any;
}

type IState = IUpdateAccountState;

export const initialUpdateAccountState: IState = new EndpointStateFactory<IState>().getInitialState({
  updateAccountRequest: [
    {
      IsAad: false,
      newWorkEmail: '',
      oldWorkEmail: ''
    }
  ],
  updateAccountResponse: [
    {
      IsAad: false,
      newWorkEmail: '',
      oldWorkEmail: '',
      result: ''
    }
  ],
  multipleProfileRequest: [
    {
      workEmail: ''
    }
  ],
  multipleProfileResponse: [
    {
      id: '',
      workEmail: '',
      emailAddress: ''
    }
  ],
  deleteDuplicateProfileRequest: [
    {
      learnerId: ''
    }
  ],
  deleteDuplicateProfileResponse: [
    {
      result: ''
    }
  ],
  offerTransactionResponse: {}
});

/**
 * Reducer responsible for managing the slice of state that belongs to the store
 *
 * @param state - Initial account update state
 * @param action - The action
 */
const reducer: Reducer<IState> = (state: IState = initialUpdateAccountState, action: AnyAction): IState => {
  switch (action.type) {
    case GetUpdateAccountWorkEmailOnlyActions.ActionTypes.ExecuteSuccess:
      return {
        ...state,
        updateAccountResponse: action.payload
      };

    case GetUpdateAccountActions.ActionTypes.ExecuteSuccess: {
      return {
        ...state,
        updateAccountResponse: action.payload
      };
    }
    case GetUpdateAccountOidEmailAddressOnlyActions.ActionTypes.ExecuteSuccess: {
      return {
        ...state,
        updateAccountResponse: action.payload
      };
    }
    case GetMultipleProfiles.ActionTypes.ExecuteSuccess: {
      return {
        ...state,
        multipleProfileResponse: action.payload
      };
    }
    case DeleteDuplicateProfile.ActionTypes.ExecuteSuccess: {
      return {
        ...state,
        deleteDuplicateProfileResponse: action.payload
      };
    }
    case OfferTransHistoryActions.ActionTypes.ExecuteSuccess: {
      return {
        ...state,
        offerTransactionResponse: action.payload
      };
    }
    default:
      return state;
  }
};

/** Responsible for handling the related state in the Redux store */
export const UpdateAccountStore = new EndpointStore<IState>(storeName, initialUpdateAccountState, reducer, sagas, [
  GetUpdateAccountWorkEmailOnlyActions,
  GetUpdateAccountActions,
  GetUpdateAccountOidEmailAddressOnlyActions,
  GetMultipleProfiles,
  DeleteDuplicateProfile,
  OfferTransHistoryActions
]);
