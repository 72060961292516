import { ILocale, LocalizedMessages } from './components/LocalizationProvider/LocalizationProvider';

export const locales: ILocale[] = [
  {
    locale: 'en-US',
    englishName: 'English (United States)',
    displayName: 'English (United States)',
    rtl: false,
    visible: true
  },
  {
    locale: 'ja-JP',
    englishName: 'Japanese',
    displayName: '日本語',
    rtl: false,
    visible: true
  },
  {
    locale: 'ko-KR',
    englishName: 'Korean',
    displayName: '한국어',
    rtl: false,
    visible: true
  },
  {
    locale: 'zh-CN',
    englishName: 'Chinese (Simplified)',
    displayName: '中文 (简体)',
    rtl: false,
    visible: true
  },

  //Enabling this locale for Supportive Language Dropdown on ESIAgreement Page
  //It will not be visible on LXP footer locale page
  {
    locale: 'de-DE',
    englishName: 'German',
    displayName: 'Deutsch',
    rtl: false,
    visible: false
  },

  //Enabling this locale for Supportive Language Dropdown on ESIAgreement Page
  //It will not be visible on LXP footer locale page
  {
    locale: 'es-ES',
    englishName: 'Spanish',
    displayName: 'Español',
    rtl: false,
    visible: true
  },
  // {
  //   locale: 'ar-SA',
  //   englishName: 'Arabic',
  //   displayName: 'عربى',
  //   rtl: true
  // },
  // {
  //   locale: 'it-IT',
  //   englishName: 'Italian',
  //   displayName: 'Italiani',
  //   rtl: false
  // },

  //Enabling this locale for Supportive Language Dropdown on ESIAgreement Page
  //It will not be visible on LXP footer locale page
  {
    locale: 'fr-FR',
    englishName: 'French',
    displayName: 'Français',
    rtl: false,
    visible: true
  },
  // {
  //   locale: 'zh-TW',
  //   englishName: 'Traditional Chinese',
  //   displayName: '繁體中文',
  //   rtl: false
  // },

  //Enabling this locale for Supportive Language Dropdown on ESIAgreement Page
  //It will not be visible on LXP footer locale page
  {
    locale: 'pt-BR',
    englishName: 'Brazilian Portuguese',
    displayName: 'Portugues do Brasil',
    rtl: false,
    visible: true
  }
  // {
  //   locale: 'ru-RU',
  //   englishName: 'Russian',
  //   displayName: 'русский',
  //   rtl: false
  // }
];

export const loadLocaleData = async (locale: string): Promise<LocalizedMessages> => {
  if (locale === 'en-US' || locale === 'ko-KR') {
    locale = locale.toLowerCase();
    }
  else if (locale === 'de-DE') {
      return (await import('./i18n/translated/de-DE.json')).default;
  }
  return (await import(`./i18n/translated/${locale}/strings.json`)).default;
};
