/**
 * Application level settings
 * Use .env, .env.development, .env.test, .env.production variables to inject environment
 * specific values into the settings
 */
import { AccountInfo, LogLevel, PublicClientApplication, RedirectRequest, SilentRequest } from '@azure/msal-browser';

import { ai } from './components/ApplicationInsightsProvider/ApplicationInsightsService';
import { useAuthority } from './hooks';
import { IServiceConfiguration } from './store/endpoint';
import { QueryStringParam, Helper, isIE } from './utilities';

export interface IApplicationConfig {
  services: {
    [key: string]: IServiceConfiguration;
  };
  msal: PublicClientApplication;
  defaultAuthScopes: string[];
}

export const applicationConfig: IApplicationConfig = {
  services: {
    accessCode: {
      apiKey: process.env.REACT_APP_API_ENROLLMENTSERVICES_Apim_Subscription_Key ?? '',
      base: process.env.REACT_APP_API_ACCESSCODE_URL + '/accesscode/v1',
      endpoints: {
        sendAccessCode: '/AccessCode/GetAccessCode',
        verifyAccessCode: '/AccessCode/ValidateAccessCode'
      }
    },
    offerDiscount: {
      apiKey: process.env.REACT_APP_API_ENROLLMENTSERVICES_Apim_Subscription_Key ?? '',
      base: process.env.REACT_APP_API_DISCOUNTOFFER_URL ?? '',
      endpoints: {
        offerTransactionHistory: '/Offers/GetOfferTransactionHistory'
      }
    },
    enrollmentServices: {
      apiKey: process.env.REACT_APP_API_ENROLLMENTSERVICES_Apim_Subscription_Key ?? '',
      base: process.env.REACT_APP_API_ENROLLMENTSERVICES_URL + '/api/',
      endpoints: {
        learningPlan: (learningPlanId: string) => `LearningPlan/${Helper.getLocale()}/${learningPlanId}`,
        learningPlanCard: (learningPlanId: string) => `LearningPlan/default/${Helper.getLocale()}/${learningPlanId}`,
        myDeliveries: `LearningPlan/myDeliveries`,
        certification: 'Certification/certifications',
        profile: 'profile',
        checkUserPartOf: 'profile/checkUserPartOf',
        emailValidationClaims: 'profile/customerinfo',
        readFeatureFlag: 'profile/ReadFeatureFlag',
        emailVerificationClaims: 'EmailVerification/SendVerificationEmail',
        verificationCodeClaims: 'EmailVerification/VerifyEmailAccessCode',
        accountLinking: 'AccountLinking',
        languages: () => `DomainData/language/${Helper.getLocale()}`,
        modalities: () => `DomainData/modality/${Helper.getLocale()}`,
        notifications: (pageName: string) => `DomainData/notifications/${Helper.getLocale()}/${pageName}`,
        timezones: () => `DomainData/timezone/${Helper.getLocale()}`,
        esiAgreement: (langCode: string) => `DomainData/esiterms/${langCode}`,
        roles: () => `DomainData/role/${Helper.getLocale()}`,
        measureUpLTISettings: 'DomainData/measureupLTISettings',
        emailServiceStatus: 'DomainData/emailServiceStatus',
        mscatalog: 'LearningPlan/deliverycatalog',
        learningPartner: (tpId: string) => `LearningPlan/learningpartners/${Helper.getLocale()}/${tpId}`,
        deliverydetails: 'LearningPlan/deliverydetails',
        eventRegistration: `EventManagement/eventregistration`,
        cancelRegistration: 'EventManagement/eventcancellation',
        cancelWaitlist: 'EventManagement/eventcancellationWaitlist',
        confirmRegistration: 'EventManagement/confirmRegistration',
        country: () => `DomainData/country/${Helper.getLocale()}`,
        deleteAccount: 'Profile/deleteAccount',
        updateWorkEmailAddress: 'Profile/updateWorkEmailAddress',
        verifyWorkEmailAddress: 'Profile/verifyWorkEmailAddress',
        updateAccountWorkEmailOnly: 'profile/updateAccountWorkEmailOnly',
        verifyCleanupUser: 'profile/verifyCleanupUser',
        unlinkEmailAddressError: 'profile/unlinkEmailAddressError',
        updateAccountOIDEmailAddressOnly: 'profile/updateAccountOIDEmailAddressOnly',
        updateAccount: 'profile/updateAccount',
        multipleProfiles: 'profile/support',
        deleteDuplicateProfile: 'Profile/delete',
        emailCodeVerificationStatus: 'DomainData/codeverificationstatus',
        tokensAvailable: `profile/tokensAvailable`,
        sendRequestForAdditonalTokens: 'profile/sendRequestForAdditonalTokens',
        learningPathGroups: () => `LearningPlan/learningPathGroups/${Helper.getLocale()}`,
        searchDeliveriesStatus: `LearningPlan/searchDeliveriesStatus`,
        eventSessions: `EventManagement/eventSessions`,
        resendInvite: `EventManagement/resendInvite`,
        ssoStatus: (domain: string) => `profile/getlfossodomainstatus/${domain}`,
        ssoLinkedAccounts: `profile/getaccountbylearnlinkedaccount`,
        copilotTokensRequest: (learnerId: string) => `LfoService/directLineToken/${learnerId}`
      }
    }
  },
  msal: new PublicClientApplication({
    auth: {
      authority: useAuthority(),
      knownAuthorities: [],
      clientId: process.env.REACT_APP_AUTHENTICATION_CLIENT_ID || '',
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      navigateToLoginRequestUrl: true
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              if (ai.appInsights) {
                const exception = new Error(message);
                exception.name = 'MSAL Logger Error';
                ai.appInsights.trackException({ exception });
              }
              // eslint-disable-next-line no-console
              console.log('[MSAL]', message);
              return;
          }
        },
        piiLoggingEnabled: true
      },
      asyncPopups: false
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: isIE()
    }
  }),
  defaultAuthScopes: ['api://' + process.env.REACT_APP_AUTHENTICATION_CLIENT_ID + '/user_impersonation']
};
class ApplicationSettings {
  public config: IApplicationConfig;
  constructor() {
    this.config = applicationConfig;
  }
}
export const appSettings = new ApplicationSettings();
const account = appSettings.config.msal.getAllAccounts()?.[0] || ({} as AccountInfo);
export const silentRequest: SilentRequest = {
  account: account,
  scopes: ['api://' + process.env.REACT_APP_AUTHENTICATION_CLIENT_ID + '/user_impersonation'],
  redirectUri: window.location.origin,
  extraQueryParameters: {
    // eslint-disable-next-line camelcase
    instance_aware: 'true'
  }
};

export const redirectRequestAAD: RedirectRequest = {
  account: account,
  scopes: ['api://' + process.env.REACT_APP_AUTHENTICATION_CLIENT_ID + '/user_impersonation'],
  loginHint: sessionStorage.getItem(QueryStringParam.WorkEmailAddress) || '',
  redirectUri: window.location.origin,
  authority: useAuthority(),
  // eslint-disable-next-line camelcase
  extraQueryParameters: { domain_hint: 'organizations' }
};

export const redirectRequestNONAAD: RedirectRequest = {
  account: account,
  scopes: ['api://' + process.env.REACT_APP_AUTHENTICATION_CLIENT_ID + '/user_impersonation'],
  redirectUri: window.location.origin,
  authority: process.env.REACT_APP_AUTHENTICATION_MSA_AUTHORITY,
  prompt: 'login',
  // eslint-disable-next-line camelcase
  extraQueryParameters: { domain_hint: 'consumers' }
};

export const redirectRequestSelectAccount: RedirectRequest = {
  scopes: ['api://' + process.env.REACT_APP_AUTHENTICATION_CLIENT_ID + '/user_impersonation'],
  redirectUri: window.location.origin,
  prompt: 'select_account'
};
