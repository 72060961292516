import { AzureKeyCredential, SearchClient } from '@azure/search-documents';
import { isNaN } from 'lodash';
import axios from 'axios';

import { ICertificationSearchRecord, IDeliverySearchRecord, IFilterInputs } from '../models';

export enum SearchIndex {
  Delivery = 'deliveries',
  Certification = 'certifications',
  BlockedUsers = 'blocked-users'
}

export interface ISearchResult<T> {
  searchId: string;
  records: T[];
  count: number;
  facets: any;
}

export interface IBlockedUserResponse {
  records: string[];
}

export class SearchHelper {
  static readonly searchIdHeader = 'x-ms-azs-searchid';
  static readonly API_ENDPOINT: string = process.env.REACT_APP_SEARCH_API_ENDPOINT ?? '';
  static readonly API_KEY: string = process.env.REACT_APP_SEARCH_API_KEY ?? '';
  static readonly API_VERSION = process.env.REACT_APP_SEARCH_API_VERSION ?? '';

  private static GetClient<TModel>(index: SearchIndex): SearchClient<TModel> {
    if (this.API_ENDPOINT === '') throw 'Search API endpoint missing.';
    if (this.API_KEY === '') throw 'Search API key missing';
    if (this.API_VERSION === '') throw 'Search API version missing';

    return new SearchClient<TModel>(this.API_ENDPOINT, index as string, new AzureKeyCredential(this.API_KEY), {
      apiVersion: this.API_VERSION
    });
  }

  static async GetCertifications(query: string, filters: IFilterInputs, top = 0, skip = 0, accessToken: string) {
    return await SearchHelper.Search<ICertificationSearchRecord>(
      query,
      [
        'catalogId',
        'certId',
        'certification',
        'certificationRole',
        'description',
        'id',
        'image',
        'platform',
        'url',
        'learntvurl',
        'mslearnurl'
      ],
      `locale eq '${filters.locale?.toLowerCase()}'`,
      [],
      [],
      SearchIndex.Certification,
      top,
      [],
      skip,
      accessToken
    );
  }

  static async GetDeliveries(
    query: string,
    filters: IFilterInputs,
    isDisabledMultiRoleAndFiltersInUrl = false,
    ignoreLearningOptions = false,
    isExtraSearchODataFiltersEnabled: boolean,
    top = 0,
    skip = 0,
    disableProjectReadiness: boolean,
    accessToken: string
  ) {
    const learningContentType = filters.learningContentType;
    const twoDaysFromNow = new Date();
    const twoDaysFromNowVTD = new Date();
    const now = new Date();
    if (disableProjectReadiness) {
      twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);
    } else {
      twoDaysFromNow.setDate(twoDaysFromNow.getDate());
      twoDaysFromNowVTD.setDate(twoDaysFromNow.getDate() + 2);
    }

    const vtdLookAheadDays = new Date();
    vtdLookAheadDays.setDate(vtdLookAheadDays.getDate() + 365);

    const otherLookAheadDays = new Date();
    otherLookAheadDays.setDate(vtdLookAheadDays.getDate() + 60);

    let oDataFilter = `(TPID eq '${filters.tpId}') and languageCode eq '${filters.locale}' `;
    let businessRules = isExtraSearchODataFiltersEnabled
      ? `and CatalogClassificationDesc ne 'Exam Prep'  and (DeliveryOffcialStartDateTime ge ${twoDaysFromNow.toISOString()})`
      : `and esi_name eq 'Confirmed & Available'and LxPDisplayDate le ${now.toISOString()}  and ((DeliveryOffcialStartDateTime le ${otherLookAheadDays.toISOString()} and CatalogClassificationDesc ne 'Virtual Training Days') or (DeliveryOffcialStartDateTime le ${vtdLookAheadDays.toISOString()} and CatalogClassificationDesc eq 'Virtual Training Days') ) and ((msevtmgt_stopwebsiteregistrationson ge ${now.toISOString()}) or (msevtmgt_stopwebsiteregistrationson eq null and DeliveryOffcialStartDateTime ge ${twoDaysFromNow.toISOString()}))`;

    if (!disableProjectReadiness) {
      businessRules = isExtraSearchODataFiltersEnabled
        ? `and CatalogClassificationDesc ne 'Exam Prep'  and (DeliveryOffcialStartDateTime ge ${twoDaysFromNow.toISOString()})`
        : `and esi_name eq 'Confirmed & Available'and LxPDisplayDate le ${now.toISOString()}  and ((DeliveryOffcialStartDateTime le ${otherLookAheadDays.toISOString()} and CatalogClassificationDesc ne 'Virtual Training Days') or (DeliveryOffcialStartDateTime le ${vtdLookAheadDays.toISOString()} and CatalogClassificationDesc eq 'Virtual Training Days') ) and ((msevtmgt_stopwebsiteregistrationson ge ${now.toISOString()}) or (msevtmgt_stopwebsiteregistrationson eq null and CatalogClassificationDesc eq 'Virtual Training Days' and DeliveryOffcialStartDateTime ge ${twoDaysFromNowVTD.toISOString()}))`;
    }

    oDataFilter += ` ${businessRules}`;

    // learning options
    if (!ignoreLearningOptions && learningContentType !== 'all' && learningContentType !== '')
      oDataFilter += ` and CatalogClassificationDesc eq '${learningContentType}' `;

    // languages
    if (filters.languageList.length > 0 && filters.languageList[0] !== '') {
      let oDataLanguageFilter = '(';
      for (const languageCode of filters.languageList) {
        if (languageCode.includes("'") && !isDisabledMultiRoleAndFiltersInUrl) {
          const escapedLanguageCode = languageCode.replaceAll("'", "''");
          oDataLanguageFilter += `DeliveryLanguageDesc eq '${escapedLanguageCode}' or `;
        } else oDataLanguageFilter += `DeliveryLanguageDesc eq '${languageCode}' or `;
      }
      oDataLanguageFilter = `${oDataLanguageFilter.substring(0, oDataLanguageFilter.length - ' or '.length)})`;

      oDataFilter += ` and ${oDataLanguageFilter}`;
    }

    // roles
    if (filters.targetedRole.length > 0 && filters.targetedRole[0] !== '') {
      let allreadyadded = false;
      let oDataRoleFilter = '(';

      const RoleOptionVTD = ['Business Owner', 'Business User', 'Technology Manager', 'Technology Educator'];
      if (learningContentType === 'Virtual Training Days') {
        if (!filters.targetedRole.includes('Fundamental Skills')) {
          //eslint-disable-next-line
          oDataRoleFilter += `search.ismatch('\"Fundamental Skills\"','TargetedRole') or `;
          allreadyadded = true;
        }
      }
      if (
        !allreadyadded &&
        !filters.targetedRole.includes('Fundamental Skills') &&
        filters.targetedRole.some(r => RoleOptionVTD.includes(r))
      ) {
        //eslint-disable-next-line
        oDataRoleFilter += `search.ismatch('\"Fundamental Skills\"','TargetedRole') or `;
      }
      if (!isDisabledMultiRoleAndFiltersInUrl) {
        //eslint-disable-next-line
        for (let role of filters.targetedRole) {
          role = role.replaceAll("'", "''");
          oDataRoleFilter += `search.ismatch('"${role}"','TargetedRole') or `;
        }
      } else {
        for (const role of filters.targetedRole) oDataRoleFilter += `TargetedRole eq '${role}' or `;
      }
      oDataRoleFilter = `${oDataRoleFilter.substring(0, oDataRoleFilter.length - ' or '.length)})`;
      oDataFilter += ` and ${oDataRoleFilter}`;
    }

    // timezones
    if (filters.timezoneList.length > 0 && filters.timezoneList[0] !== '') {
      let hasValidTimezoneCode = false;
      let oDataTimezoneFilter = '(';
      for (const timezone of filters.timezoneList) {
        if (!isNaN(Number(timezone))) {
          hasValidTimezoneCode = true;
          oDataTimezoneFilter += `TimeZoneCode eq ${timezone} or `;
        }
      }
      oDataTimezoneFilter = `${oDataTimezoneFilter.substring(0, oDataTimezoneFilter.length - ' or '.length)})`;

      oDataFilter += hasValidTimezoneCode ? ` and ${oDataTimezoneFilter}` : '';
    }

    // seat availibility
    if (filters.seatsAvailability.length > 0 && filters.seatsAvailability[0] !== '') {
      let oDataSeatAvailibilityFilter = '(';
      for (const availabilty of filters.seatsAvailability) {
        if (!isDisabledMultiRoleAndFiltersInUrl) {
          const escapedAvailability = availabilty.replaceAll("'", "''");
          oDataSeatAvailibilityFilter += `SeatAvailability eq '${escapedAvailability}' or `;
        } else oDataSeatAvailibilityFilter += `SeatAvailability eq '${availabilty}' or `;
      }
      oDataSeatAvailibilityFilter = `${oDataSeatAvailibilityFilter.substring(0, oDataSeatAvailibilityFilter.length - ' or '.length)})`;

      oDataFilter += ` and ${oDataSeatAvailibilityFilter}`;
    }

    //Modality
    if (filters.targetedModality.length > 0 && filters.targetedModality[0] !== '') {
      let oDataModalityFilter = '(';
      for (const modality of filters.targetedModality) {
        if (!isDisabledMultiRoleAndFiltersInUrl) {
          const escapedModality = modality.replaceAll("'", "''");
          oDataModalityFilter += `LearningExperience eq '${escapedModality}' or `;
        } else oDataModalityFilter += `LearningExperience eq '${modality}' or `;
      }
      oDataModalityFilter = `${oDataModalityFilter.substring(0, oDataModalityFilter.length - ' or '.length)})`;

      oDataFilter += ` and ${oDataModalityFilter}`;
    }

    //delivery Start Time
    if (filters.deliveryStartTime && filters.deliveryEndTime) {
      const startDateTime = new Date('1900-01-01T' + filters.deliveryStartTime + ':00Z').toISOString().split('.')[0] + 'Z';
      const endDateTime = new Date('1900-01-01T' + filters.deliveryEndTime + ':00Z').toISOString().split('.')[0] + 'Z';
      let oDeliveryTimeRangeFilter = '(';
      oDeliveryTimeRangeFilter += `DeliveryStartTime ge ${startDateTime} and DeliveryStartTime le ${endDateTime}`;
      oDataFilter += ` and ${oDeliveryTimeRangeFilter})`;
    }

    const maxFacetCount = 50;

    const facetsSend = !isDisabledMultiRoleAndFiltersInUrl
      ? [
          'CatalogClassificationDesc',
          `TargetedRole,count:${maxFacetCount}`,
          'SeatAvailability',
          `DeliveryLanguageDesc,count:${maxFacetCount}`,
          `TimeZone,count:${maxFacetCount}`,
          `FormattedTimeZones,count:${maxFacetCount}`,
          'LearningExperience'
        ]
      : [
          'CatalogClassificationDesc',
          `TargetedRole,count:${maxFacetCount}`,
          'SeatAvailability',
          `DeliveryLanguageDesc,count:${maxFacetCount}`,
          `TimeZone,count:${maxFacetCount}`,
          'LearningExperience'
        ];

    const scoringParam = ['Modality:BLXP', 'Availability:Register'];
    return await SearchHelper.Search<IDeliverySearchRecord>(
      query,
      [
        'Id',
        'AvailableSeats',
        'CatalogClassificationDesc',
        'CatalogDesc',
        'CatalogPrerequisiteAttestment',
        'CourseCapacity',
        'DeliveryCatalogID',
        'DeliveryCatalogTitle',
        'DeliveryCountry',
        'DeliveryDuration',
        'DeliveryID',
        'DeliveryLanguageDesc',
        'DeliveryLocation',
        'DeliveryModality',
        'DeliveryModalityDesc',
        'DeliveryOffcialStartDateTime',
        'DeliveryRegFormURL',
        'DeliveryTypeDesc',
        'EventId',
        'LxPDisplayDate',
        'LXPTitle',
        'ReadableEventId',
        'RegistrationCount',
        'TargetedRole',
        'TimeRangeCalc',
        'TimeZone',
        'TimeZoneCode',
        'TPID',
        'DateRangeCalc',
        'DeliveryStartTime',
        'CatalogPrerequisite',
        'DeliveryStatus',
        'TeamsConsent',
        'BlendedLearningScheduleUrl',
        'MicrosoftTeamsUrl',
        'MicrosoftTeamsGroupId',
        'session_count'
      ],
      oDataFilter.trim(),
      facetsSend,
      [],
      SearchIndex.Delivery,
      top,
      scoringParam,
      skip,
      accessToken
    );
  }

  static async Search<TModel>(
    text: string,
    selectColumns: (keyof TModel)[],
    filter = '',
    facets: string[] = [],
    orderBy: string[] = [],
    index: SearchIndex,
    top = 1000,
    scoringParam: string[] = [],
    skip = 0,
    accessToken: string
  ): Promise<ISearchResult<TModel>> {
    let searchId: string | undefined;

    let searchFilter: any = {};

    searchFilter = {
      queryType: 'simple',
      searchMode: 'any',
      searchFields: '',
      select: selectColumns.toString(),
      filter: filter,
      facets: facets,
      top: top,
      skip: skip,
      orderby: orderBy.length == 0 ? '' : orderBy,
      count: true,
      scoringParameters: scoringParam,
      scoringStatistics: 'global',
      search: text
    };
    const searchUrl = 'search.post.search';
    const searchResults: any = await SearchHelper.GetSearchResult(searchUrl, index, searchFilter, accessToken);
    const records: TModel[] = [];
    if (searchResults && searchResults.data.value && searchResults.data.value.length > 0)
      for await (const result of searchResults.data.value) records.push(result);

    return {
      searchId,
      count: searchResults.data['@odata.count'] ?? 0,
      records: records,
      facets: searchResults.data['@search.facets']
    } as ISearchResult<TModel>;
  }

  private static async GetSearchResult(searchUrl: string, index: SearchIndex, searchFilter: any, accessToken: string) {
    const url = `${searchUrl}?api-version=` + this.API_VERSION;
    const searchResults: any = await axios.post(
      `${process.env.REACT_APP_API_ENROLLMENTSERVICES_URL}/api/search/indexes('${index}')/docs/${url}`,
      searchFilter,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_ENROLLMENTSERVICES_Apim_Subscription_Key ?? ''
        }
      }
    );
    return searchResults;
  }

  static async GetDeliverySuggestions(searchTerm: string, tpId: string, locale: string, accessToken: string): Promise<string[]> {
    const suggesterName = 'deliverysuggestions';
    const filter = `TPID eq '${tpId}' and languageCode eq '${locale}' and CatalogClassificationDesc ne 'Exam Prep'`;
    let searchFilter: any = {};

    searchFilter = {
      filter: filter,
      search: searchTerm,
      select: 'DeliveryCatalogID',
      suggesterName: suggesterName,
      top: 20
    };

    const searchUrl = 'search.post.suggest';
    const suggestions: any = await SearchHelper.GetSearchResult(searchUrl, SearchIndex.Delivery, searchFilter, accessToken);
    return suggestions.data.value.map(
      (result: { [x: string]: any; DeliveryCatalogID: any }) => `${result.DeliveryCatalogID} | ${result['@search.text']}`
    );
  }

  static async BlockedUsers(learnerId: string, top = 0, accessToken: string) {
    return await SearchHelper.Search<IBlockedUserResponse>(
      `"${learnerId}"`,
      [],
      '',
      [],
      [],
      SearchIndex.BlockedUsers,
      top,
      [],
      0,
      accessToken
    );
  }
}
