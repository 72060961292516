import mainGetMSCerts from '../assets/images/Main_GetMSCertsIconNav.svg';
import mainFindMoreClasses from '../assets/images/Main_FindMoreClassesIconNav.svg';
import mainLearnYourOwn from '../assets/images/Main_LearnYourOwnIconNav.svg';
import mainMultidayMSClass from '../assets/images/Main_MultidayMSClassIconNav.svg';
import mainOneDayMSClass from '../assets/images/Main_OneDayMSIconNav.svg';
import mainGameLearning from '../assets/images/Main_GameLearningIconNav.svg';

type Dictionary<T> = { [key: string]: T };

/* eslint-disable camelcase */
export const imagesSVG: Dictionary<string> = {
  Main_LearnYourOwn: mainLearnYourOwn,
  Main_OneDayMSClass: mainOneDayMSClass,
  Main_GetMSCerts: mainGetMSCerts,
  Main_MultidayMSClass: mainMultidayMSClass,
  Main_FindMoreClasses: mainFindMoreClasses,
  Main_GameLearning: mainGameLearning
};
