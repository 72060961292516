import { FocusZone, FontIcon, Icon, Image, Link } from '@fluentui/react';
import React, { useEffect, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { QueryStringParam } from '../../utilities';
import { ai } from '../../components/ApplicationInsightsProvider/ApplicationInsightsService';
import { ILearningPathCard, IStepToSuccessItem, ICustomErrorMessage } from '../../models';
import { IApplicationState } from '../../store';
import { useEndpointActions } from '../../store/endpoint';
import { GetProfileActions, GetSSOStatusActions } from '../../store/stores/user';
import { GetLearningPathCardActions } from '../../store/stores/learningPath';
import TaskListContrast from '../../assets/images/Task_List_Contrast.svg';
import Info from '../../assets/images/Infomenu.svg';
import Profile from '../../assets/images/Profilemenu.svg';
import { Helper } from '../../utilities/Helper';
import SearchTextBox from '../GlobalSearch/SearchTextBox';
import FeatureFlagValues from '../../utilities/featureFlagValues';
import { useStepsToSuccessPages } from './stepsToSuccessPages';
import { imagesSVG } from '../../utilities/imagesSVG';
import CopilotLogo from '../../assets/images/coassistLogomenu.svg';

import './SideBarNavigation.scss';
import './RightSideBarNavigation.scss';

export interface IComponentProps {
  /** showPersona - general info page */
  onDismiss?: () => void;
  toggleCopilotVisibility: () => void;
  enableCopilotForAccount: boolean;
  isOpen: boolean;
}

export const RightSideBarNavigation: React.FunctionComponent<IComponentProps> = props => {
  let searchFeature = {};
  let ULJPhase2 = {};
  try {
    searchFeature = FeatureFlagValues().searchFeature;
    ULJPhase2 = FeatureFlagValues()?.hideULJPhase2;
  } catch (error) {
    if (ai.appInsights) {
      ai.appInsights.trackException({
        exception: new Error('ESIUI'),
        severityLevel: SeverityLevel.Error,
        properties: { searchFeature }
      });
    }
  } finally {
    // how do we want to set the flag?
  }

  const { onDismiss, toggleCopilotVisibility, enableCopilotForAccount } = props;
  const onClose = useCallback(() => {
    if (onDismiss) {
      onDismiss();
    }
  }, [onDismiss]);

  const onCopilotClick = useCallback(() => {
    toggleCopilotVisibility();
    onClose();
  }, [onClose, toggleCopilotVisibility]);

  const onCopilotKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onCopilotClick();
      }
    },
    [onCopilotClick]
  );

  const [carditemlist, activeLearningPlanId, profile, profileIsSSOEnabled, isSSOServerError, serverErrorMessage] = useSelector(
    (state: IApplicationState) => [
      state.LearningPathStore.learningPathCards,
      state.UserStore.profile.activeLearningPlanId,
      state.UserStore.profile,
      state.UserStore.ssoprofile.isDomainEligibleForSso,
      state.UserStore.isSSOError,
      state.UserStore.customErrorMessage
    ],
    shallowEqual
  );
  const disableSSOFeatureFlag = FeatureFlagValues().disableLearnSSOForLandingPageAndNavs;
  const disableSSO = !profileIsSSOEnabled || disableSSOFeatureFlag;

  useEffect(() => {
    if (isSSOServerError) {
      const friendlyError: ICustomErrorMessage = {
        description: serverErrorMessage?.toString(),
        errorCode: serverErrorMessage?.toString(),
        method: 'UseEffect-Servererror',
        page: 'RightSideBarNavigation'
      };
      if (ai.appInsights) {
        ai.appInsights.trackException({
          exception: new Error('ESIUI'),
          severityLevel: SeverityLevel.Error,
          properties: { friendlyError, isSSOServerError }
        });
      }
    }
  }, [isSSOServerError, serverErrorMessage]);

  const [getProfile, getLearningPathCards, getSSOStatus] = useEndpointActions([
    GetProfileActions,
    GetLearningPathCardActions,
    GetSSOStatusActions
  ]);
  const userEmail = sessionStorage.getItem(QueryStringParam.WorkEmailAddress);

  useEffect(() => {
    getProfile(userEmail);
    const userDomain = Helper.getDomain(userEmail);
    if (userDomain != null) {
      getSSOStatus(userDomain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeLearningPlanId) {
      getLearningPathCards(activeLearningPlanId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLearningPlanId]);
  const hascarditemlist = Array.isArray(carditemlist);

  const stepsToSuccessPages = useStepsToSuccessPages();

  const [searchTextBoxVisible, setSearchTextBoxVisible] = React.useState(false);
  const hideUljPhase2 = ULJPhase2 ? 2 : stepsToSuccessPages.length;

  const showSearchDialogEnterPressed = (event: any) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      showSearchDialog();
    }
  };

  const showSearchDialog = () => {
    setSearchTextBoxVisible(true);
  };

  const onContinueClose = (onCompletionClose: boolean) => {
    if (onCompletionClose) {
      onClose();
    }
  };

  const onCloseEnterPressed = (event: any) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      onClose();
    }
  };

  return (
    <React.Fragment>
      {hascarditemlist && (
        <ul className="rightNavUl">
          {!searchTextBoxVisible ? (
            <li>
              <div className="menuBarContentNav menubarHeader" role="none">
                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row gridDivRight">
                    <div className="ms-Grid-col ms-sm7 ms-lg7">
                      <FocusZone>
                        <h2 className="spaceHome ms-fontWeight-semibold">
                          <NavLink className="menuBarHome" to="/landing" data-is-focusable="true">
                            <FormattedMessage defaultMessage="Home" />
                          </NavLink>
                        </h2>
                      </FocusZone>
                    </div>
                    {searchFeature && (
                      <div role="presentation" className="ms-Grid-col ms-sm2 ms-lg2 searchButton" onKeyDown={showSearchDialogEnterPressed}>
                        <FontIcon tabIndex={0} className="searchIcon" aria-label="Search" iconName="Search" onClick={showSearchDialog} />
                      </div>
                    )}
                    <div className="ms-Grid-col ms-sm3 ms-lg3 cancelButton">
                      <FocusZone>
                        <div role="presentation" className="navBarButton" data-is-focusable="true" onKeyDown={onCloseEnterPressed}>
                          <Icon onClick={onClose} iconName="Cancel" aria-label="Close button" />
                        </div>
                      </FocusZone>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ) : (
            <li>
              <div className="searchBarDiv" role="none">
                <div className="ms-Grid-col ms-sm8 ms-lg8">
                  <SearchTextBox defaultUrl="/Search" searchIcon="none" onContinue={onContinueClose} isHamburger={true}></SearchTextBox>
                </div>
                <div className="ms-Grid-col ms-sm4 ms-lg4">
                  <div className="cancelAlignment" data-is-focusable="true">
                    <Link onClick={onClose}> Cancel </Link>
                  </div>
                </div>
              </div>
            </li>
          )}
          {carditemlist !== null &&
            carditemlist.map((carditem: ILearningPathCard, index: number) => (
              <li key={index}>
                <FocusZone>
                  <div className="menuBarContentNav" onClick={onClose} role="none">
                    {carditem.cardlink.includes('http') && (
                      <a
                        href={Helper.GetUrlWithRole(carditem, profile?.roleName)}
                        target="_blank" // eslint-disable-line react/jsx-no-target-blank
                        rel="noopener"
                        onClick={Helper.handleLearnRedirectEvent(
                          disableSSO,
                          Helper.GetUrlWithRole(carditem, profile?.roleName),
                          '_blank',
                          'noopener'
                        )}
                      >
                        <div className="ms-Grid " dir="ltr">
                          <div className="ms-Grid-row gridDivRight">
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                              <Image src={imagesSVG[carditem.imagename]} alt="Navigation icon" className="iconStyle" />
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                              <div className="space ms-fontWeight-semibold">
                                {carditem.cardtitle} &nbsp;
                                <FontIcon iconName="NavigateExternalInline" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    )}
                    {!carditem.cardlink.includes('http') && (
                      <NavLink to={carditem.cardlink} data-is-focusable="true">
                        <div className="ms-Grid" dir="ltr">
                          <div className="ms-Grid-row gridDivRight">
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                              <Image src={imagesSVG[carditem.imagename]} alt="Navigation icon" className="iconStyle" />
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                              <div className="space ms-fontWeight-semibold">{carditem.cardtitle}</div>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    )}
                  </div>
                </FocusZone>
              </li>
            ))}
          <li>
            <FocusZone>
              <div className="menuBarContentNav" onClick={onClose} role="none">
                <NavLink to="/registrations" data-is-focusable="true">
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row gridDivRight">
                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <Image src={TaskListContrast} alt="" className="iconStyle" />
                      </div>
                      <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                        <div className="space ms-fontWeight-semibold">
                          <FormattedMessage defaultMessage="My Registrations" />
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </FocusZone>
          </li>
          <li>
            <FocusZone>
              <div className="menuBarContentNav" onClick={onClose} role="none">
                <NavLink to="/settings" data-is-focusable="true">
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row gridDivRight">
                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <Image src={Profile} alt="" className="iconStyle" />
                      </div>
                      <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                        <div className="space ms-fontWeight-semibold">
                          <FormattedMessage defaultMessage="Settings" />
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </FocusZone>
          </li>
          <li>
            <FocusZone>
              <div className="menuBarContentNav" onClick={onClose} role="none">
                <NavLink to="/help" data-is-focusable="true">
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row gridDivRight">
                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <Image src={Info} alt="" className="iconStyle" />
                      </div>
                      <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                        <div className="space ms-fontWeight-semibold">
                          <FormattedMessage defaultMessage="Help" />
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </FocusZone>
          </li>
          {enableCopilotForAccount && (
            <li>
              <FocusZone>
                <div className="menuBarContentNav" onClick={onClose} role="none">
                  <div
                    role="button"
                    tabIndex={0}
                    aria-label="Open Enterprise Learner Assist"
                    onClick={onCopilotClick}
                    onKeyDown={onCopilotKeyDown}
                    className="link"
                  >
                    <div className="ms-Grid" dir="ltr">
                      <div className="ms-Grid-row gridDivRight">
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                          <Image src={CopilotLogo} alt="" className="iconStyle" />
                        </div>
                        <div className="ms-Grid-col ms-sm11 ms-md11 ms-lg11">
                          <div className="space ms-fontWeight-semibold">
                            <FormattedMessage defaultMessage="Enterprise Learner Assist" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FocusZone>
            </li>
          )}
          <li>
            <div className="menuBarContentNav menubarHeader" role="none">
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row gridDivRight">
                  <div className="ms-Grid-col">
                    <FocusZone>
                      <h2 className="spaceStepsToSuccess ms-fontWeight-semibold">
                        <FormattedMessage defaultMessage="Your Steps to Success" />
                      </h2>
                    </FocusZone>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <ul>
              {stepsToSuccessPages !== null &&
                stepsToSuccessPages?.slice(0, hideUljPhase2).map((stepsItem: IStepToSuccessItem, index: number) => (
                  <li key={index}>
                    <FocusZone>
                      <div className="menuBarContentNav" onClick={onClose} role="none">
                        <NavLink to={stepsItem.stepPath} className="navLinkSuccess" data-is-focusable="true">
                          <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row gridDivRight">
                              <div className="ms-Grid-col ms-sm11 ms-md11 numberedListItem">
                                <span className="space">{stepsItem.stepName}</span>
                              </div>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </FocusZone>
                  </li>
                ))}
            </ul>
          </li>
        </ul>
      )}
    </React.Fragment>
  );
};
export default React.memo(RightSideBarNavigation);
