import * as React from 'react';
import { Callout, Icon, Image, Link, Text, getTheme, FontWeights, mergeStyleSets } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDisclosure, useLogout } from '../../hooks';
import User from '../../assets/images/User.svg';
import UserContrast from '../../assets/images/User_Contrast.svg';

const theme = getTheme();
const styles = mergeStyleSets({
  buttonArea: {
    margin: '0 0 0 10px'
  },
  callout: {
    width: '290px',
    height: '149px'
  },
  header: {
    padding: '50px 24px 12px'
  },
  close: {
    float: 'right',
    padding: '18px 34px 14px 0px',
    height: '19px',
    width: '18px',
    fontWeight: FontWeights.bold,
    fontSize: '18px',
    color: '#000 !important'
  },
  buttonheader: {
    padding: '18px 24px 12px'
  },
  title: [
    theme.fonts.medium,
    {
      margin: 0,
      fontWeight: FontWeights.regular,
      color: '#000',
      opacity: '1',
      height: '20px',
      fontSize: '15px'
    }
  ],
  inner: {
    padding: '0 24px 20px',
    height: '100%'
  },
  actions: {
    position: 'relative',
    marginTop: 20,
    width: '100%',
    whiteSpace: 'nowrap'
  },
  subtext: [
    theme.fonts.small,
    {
      margin: 0,
      fontWeight: FontWeights.semilight
    }
  ],
  link: [
    theme.fonts.medium,
    {
      color: '#0065B3',
      fontWeight: FontWeights.bold
    }
  ]
});
interface IComponentProps {
  displayName: string;
  userName: string;
}
export const PopupCallout: React.FunctionComponent<IComponentProps> = props => {
  const intl = useIntl();
  const { displayName, userName } = props;
  const signout = useLogout();
  const { isOpen, onClose, onToggle } = useDisclosure(false);
  const labelId: string = useId('calloutLabel');

  const enterPressed = (event: any) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      setTimeout(function() {
        onToggle();
      }, 100);
    }
  };

  const onLinkBlur = (e: any) => {
    e.persist();
    if (!e.relatedTarget) {
      onClose();
    }
  };

  const onSignOutClick = () => {
    const isSessionExpired = localStorage.getItem('isSessionExpired');
    if (isSessionExpired && isSessionExpired.toLowerCase() === 'yes') {
      localStorage.setItem('isSessionExpired', 'no');
    }
    signout();
  };

  return (
    <>
      <div className={styles.buttonArea}>
        <div className="navPersonaContainer">
          <Link
            id="welcomeLink"
            role="button"
            tabIndex={0}
            onKeyDown={enterPressed}
            onClick={onToggle}
            className="ms-fontSize-13 headerLink headerFocusLink"
          >
            <Image src={User} alt="" className="imageHeaderbarUser highContrastModeDisabled" />
            <Image src={UserContrast} alt="" className="imageHeaderbarUser highContrastModeEnabled" />
            <span className="headerbar">
              <FormattedMessage defaultMessage="Hello" />
            </span>
            <span className="headerbarFirst ms-fontWeight-bold">
              {displayName !== 'undefined' && displayName !== undefined && displayName !== null && displayName?.trim()?.substring(0, 9)}!{' '}
            </span>
          </Link>
        </div>
      </div>
      {isOpen && (
        <Callout
          className={styles.callout}
          role="dialog"
          aria-hidden="true"
          gapSpace={0}
          target={`.${styles.buttonArea}`}
          onDismiss={onClose}
          setInitialFocus={true}
        >
          <div>
            <Link aria-label={intl.formatMessage({ defaultMessage: 'Close fly out' })} className={styles.close} onClick={onClose}>
              <Icon iconName="Cancel" />
            </Link>
          </div>
          <div className={styles.header}>
            <Text className={styles.title} id={labelId}>
              {userName}
            </Text>
          </div>
          <div className="popupCalloutDivider" />
          <div className={styles.inner}>
            <div className={styles.actions}>
              <Link className={styles.link} href="#" onClick={onSignOutClick} target="_self" onBlur={onLinkBlur}>
                <FormattedMessage defaultMessage="Sign out" />
              </Link>
            </div>
          </div>
        </Callout>
      )}
    </>
  );
};
