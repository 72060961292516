import crypto from 'crypto';

import ReactWebChat, { createDirectLine, createStore } from 'botframework-webchat';
import { StyleOptions } from 'botframework-webchat-api';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dispatch } from 'redux';
import { shallowEqual, useSelector } from 'react-redux';
import { DefaultButton } from 'office-ui-fabric-react';
import { FormattedMessage } from 'react-intl';
import { TooltipHost, DirectionalHint, Popup, FocusTrapZone } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useHistory } from 'react-router-dom';

import { ai } from '../ApplicationInsightsProvider/ApplicationInsightsService';
import { useEndpointActions } from '../../store/endpoint';
import { GetCopilotActions } from '../../store/stores/copilot';
import { IApplicationState } from '../../store';
import { useAuthenticatedUser } from '../../hooks/useAuthenticatedUser';
import { useAccessToken } from '../../hooks/useAccessToken';
import { Helper } from '../../utilities';
import { trackCopilotChatOpened, trackCopilotChatConnectionRejected } from '../ApplicationInsightsProvider/CopilotTelemetryService';
import FeatureFlagValues from '../../utilities/featureFlagValues';
import CopilotLogo from '../CopilotLogo/CopilotLogo';

import './CopilotBot.scss';

interface ICopilotBot {
  showCopilotBot?: boolean;
  changeCopilotVisibility: (val: any) => void;
}

const createCopilotStore = (setConnectRejected: () => void) => {
  //ts-ignore
  return createStore({}, ({ dispatch }: { dispatch: Dispatch }) => (next: any) => (action: any) => {
    switch (action.type) {
      case 'DIRECT_LINE/CONNECT_REJECTED':
        setConnectRejected();
        trackCopilotChatConnectionRejected();
        break;
      case 'WEB_CHAT/SEND_MESSAGE':
        Helper.setClarityCustomTag('CopilotChatAction', 'ChatMessageSent');
        break;
      case 'WEB_CHAT/SET_SEND_BOX':
        Helper.setClarityCustomTag('CopilotChatAction', 'ChatMessageTyped');
        break;
    }
    return next(action);
  });
};

const useChatStore = () => {
  const [connectedRejected, { setTrue: setConnectRejected, setFalse: setConnectRestored }] = useBoolean(false);
  const [store, setStore] = useState(createCopilotStore(setConnectRejected));
  const [chatSessionKey, setChatSessionKey] = useState(crypto.randomBytes(20).toString('hex'));

  useEffect(() => {
    if (connectedRejected) {
      setStore(createCopilotStore(setConnectRejected));
      setChatSessionKey(crypto.randomBytes(20).toString('hex'));
      setConnectRestored();
    }
  }, [connectedRejected, setConnectRejected, setConnectRestored]);

  useEffect(() => {
    setStore(createCopilotStore(setConnectRejected));
  }, [setConnectRejected, setStore]);

  return { store, chatSessionKey };
};

/**
 * Component to verify the user has accepted the Eula before showing the children components
 *
 * @param props The properties
 */
const CopilotBot: React.FunctionComponent<ICopilotBot> = props => {
  const accessToken = useAccessToken();
  const history = useHistory();
  const [profile] = useSelector((state: IApplicationState) => [state.UserStore.profile], shallowEqual);
  const [token] = useSelector((state: IApplicationState) => [state.CopilotStore.copilot.token], shallowEqual);
  const tooltipId = useId('tooltip');
  const copilotHeader = useId('copilot-header');
  const ref = useRef<HTMLIFrameElement>(null);
  const [iframeLoad, setIframeLoad] = useState<boolean>(false);

  const { showCopilotBot } = props;
  const [learnerId, setLearnerId] = useState(profile.id);
  const { store, chatSessionKey } = useChatStore();
  const userId = 'dl_' + learnerId;

  const [getCopilotActions] = useEndpointActions([GetCopilotActions]);
  const account = useAuthenticatedUser();
  const copilotBotEnable = FeatureFlagValues().enableCopilot;
  const chatBotSrc = process.env.REACT_APP_COPILOT_URL ?? '';

  useEffect(() => {
    if (account && account.oid) {
      //setOid(account.oid);
      setLearnerId(profile.id);
    }
  }, [account, profile.id]);

  useEffect(() => {
    if (userId.substring(0, 3) === 'dl_' && userId.length > 4 && token === '') {
      getCopilotActions({ userId });
    }
  }, [getCopilotActions, token, userId]);

  useEffect(() => {
    if (iframeLoad && ai.appInsights) {
      const iframe = ref.current;
      if (iframe !== null) {
        const iWindow = iframe.contentWindow;
        if (iWindow !== null) {
          const dataToPass = {
            accessToken: accessToken,
            userDetail: account || '',
            locale: Helper.getLocale(),
            telemetryData: {
              sessionId: ai?.appInsights?.context?.sessionManager?.automaticSession?.id,
              additionalProps: {
                learnerId: learnerId
              }
            }
          };
          iWindow.postMessage(dataToPass, chatBotSrc);
        }
      }
    }
  }, [iframeLoad, learnerId, ref, chatBotSrc, accessToken, account]);

  useEffect(() => {
    const handlePostMessage = (event: { data: { link: string } }) => {
      if (event.data && event.data.link) {
        history.push(event.data.link);
      }
    };

    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, [history]);

  const styleOptions: StyleOptions = {
    bubbleBorderRadius: 8,
    bubbleFromUserBorderRadius: 8,
    suggestedActionLayout: 'stacked',
    suggestedActionBorderRadius: 8,
    suggestedActionTextColor: '#0078D7',
    suggestedActionBorderWidth: 1,
    suggestedActionBorderColor: '#0078D7',
    suggestedActionHeight: 20,
    bubbleFromUserBackground: '#E0E7FF',
    bubbleFromUserBorderColor: '#E0E7FF',
    bubbleBorderColor: '#E6E6E6',
    bubbleBorderStyle: 'solid',
    bubbleBorderWidth: 1,
    hideUploadButton: true,
    sendBoxButtonColor: '#424242'
  };
  const directLine = useMemo(() => createDirectLine({ token }), [token]);

  if (!account || !account.oid) {
    return <div></div>;
  }

  const closeCopilot = () => {
    const { changeCopilotVisibility } = props;
    Helper.setClarityCustomTag('CopilotChatAction', 'ChatClosed');
    const milliseconds = Date.now() - parseInt(localStorage.getItem('copilotChatOpened') || '0');
    const minutes = Math.floor(milliseconds / 60000);
    if (ai.appInsights) {
      ai.appInsights.trackTrace({
        message: 'Page :Lxp Assist Chat Closed Durations',
        severityLevel: SeverityLevel.Information,
        properties: {
          learnerId: profile.id,
          EmailAddress: profile.emailAddress,
          duration: minutes
        }
      });
    }
    changeCopilotVisibility(false);
    localStorage.removeItem('copilotChatOpened');
  };

  const _loadIframe = () => {
    setIframeLoad(true);
  };

  if (showCopilotBot) {
    Helper.setClarityCustomTag('CopilotChatAction', 'ChatOpened');
    trackCopilotChatOpened();
    localStorage.setItem('copilotChatOpened', Date.now().toString());
  }

  return learnerId && directLine && token ? (
    <div className="chat-container">
      <div className={showCopilotBot ? 'chat-window' : 'chat-window-hidden'}>
        <FocusTrapZone disabled={!showCopilotBot} isClickableOutsideFocusTrap forceFocusInsideTrap={false}>
          <Popup role="dialog" ariaLabelledBy={copilotHeader}>
            <div className="chat-box">
              <div className="chat-header">
                <div className="chat-header-title">
                  <CopilotLogo />
                  <span role="heading" aria-level={2} id={copilotHeader}>
                    <FormattedMessage defaultMessage="Enterprise Learner Assist" />
                  </span>
                </div>
                <TooltipHost
                  content="Close Enterprise Learner Assist"
                  id={tooltipId}
                  calloutProps={{ gapSpace: 0 }}
                  styles={{ root: { display: 'inline-block' } }}
                  directionalHint={DirectionalHint.leftCenter}
                >
                  <DefaultButton className="chatHeaderClose" onClick={closeCopilot} text="X" aria-describedby={tooltipId} />
                </TooltipHost>
              </div>
              <div className="webchat-container">
                {copilotBotEnable ? (
                  <iframe src={chatBotSrc} title="Chat Bot" ref={ref} onLoad={_loadIframe}></iframe>
                ) : (
                  <ReactWebChat
                    directLine={directLine}
                    userID={userId}
                    store={store}
                    key={chatSessionKey}
                    styleOptions={styleOptions}
                    username={learnerId}
                    // eslint-disable-next-line react/jsx-no-bind
                    overrideLocalizedStrings={strings => ({
                      ...strings,
                      TEXT_INPUT_PLACEHOLDER: 'Ask a question or type / for suggestions'
                    })}
                  />
                )}
              </div>
            </div>
          </Popup>
        </FocusTrapZone>
      </div>
    </div>
  ) : (
    <>{showCopilotBot && <div>There seems to be an error loading Enterprise Learner Assist, Please refresh screen</div>}</>
  );
};

export default CopilotBot;
