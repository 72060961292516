import React, { useState, useCallback } from 'react';
import { FocusZone } from '@fluentui/react';

import RightNavMenu from './RightNavMenu';

import './BurgerMenu.scss';

const BurgerMenu = ({
  toggleCopilotVisibility,
  enableCopilotForAccount
}: {
  toggleCopilotVisibility: () => void;
  enableCopilotForAccount: boolean;
}) => {
  const [isOpen, setisOpen] = useState(false);
  const toggle = useCallback(() => {
    setisOpen(!isOpen);
  }, [isOpen]);

  const escapePressed = (event: any) => {
    if (event.key === 'Escape') {
      setTimeout(function() {
        if (isOpen) {
          toggle();
        }
      }, 100);
    }
  };

  if (isOpen) {
    document.body.addEventListener('keydown', function(e) {
      if (e.key === 'Escape') {
        toggle();
      }
    });
  }
  return (
    <React.Fragment>
      <FocusZone>
        <div className="burgerMenu" role="none" onKeyDown={escapePressed} onClick={toggle} data-is-focusable="true">
          <div />
          <div />
          <div />
        </div>
      </FocusZone>
      <RightNavMenu
        data-id="rightNavMenu"
        isOpen={!isOpen}
        onClose={toggle}
        toggleCopilotVisibility={toggleCopilotVisibility}
        enableCopilotForAccount={enableCopilotForAccount}
      />
    </React.Fragment>
  );
};
export default BurgerMenu;
